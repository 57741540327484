import { configureStore } from '@reduxjs/toolkit';
import { WizardSlice } from './slices/wizardSlice';
import { categorySlice } from './slices/categorySlice';
import { productSlice } from './slices/productSlice';
import { modifiersSlice } from './slices/modifiersSlice';
import { productsSlice } from './slices/productsSlice';
import { textBlockSlice } from './slices/textBlockSlice';
import { storiesSlice } from './slices/storiesSlice';
import { selectionSlice } from './slices/selectionsSlice';
import { configSlice } from './slices/configSlice';
import { sliderSlice } from './slices/sliderSlice';
import { uiBlocksSlice } from './slices/uiBlocksSlice';
import { catalogMetadataSlice } from './slices/catalogMetadataSlice';
import { hubApi } from './middleware/hubApi';
import { PortClientApi } from './middleware/portClientApi';
import { PortOwnerApi } from './middleware/portOwnerApi';
import { IConfig } from '../utils/config/config.interface';
import { filesUpload } from './middleware/filesUpload';
import { userSlice } from './slices/userSlice';
import { recomendationSlice } from './slices/recomendationSlice';
import { uploadedFilesSlice } from './slices/uploadedFilesSlice';
import { subscriptionSlice } from './slices/subscriptionSlice';
import { billingApi } from './middleware/billingApi';

export const getStore = (config: IConfig) =>
  configureStore({
    reducer: {
      [WizardSlice.name]: WizardSlice.reducer,
      [categorySlice.name]: categorySlice.reducer,
      [textBlockSlice.name]: textBlockSlice.reducer,
      [productSlice.name]: productSlice.reducer,
      [productsSlice.name]: productsSlice.reducer,
      [modifiersSlice.name]: modifiersSlice.reducer,
      [storiesSlice.name]: storiesSlice.reducer,
      [selectionSlice.name]: selectionSlice.reducer,
      [configSlice.name]: configSlice.reducer,
      [sliderSlice.name]: sliderSlice.reducer,
      [uiBlocksSlice.name]: uiBlocksSlice.reducer,
      [catalogMetadataSlice.name]: catalogMetadataSlice.reducer,
      [userSlice.name]: userSlice.reducer,
      [recomendationSlice.name]: recomendationSlice.reducer,
      [uploadedFilesSlice.name]: uploadedFilesSlice.reducer,
      [subscriptionSlice.name]: subscriptionSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            hubApi: hubApi(config.hubApiUrl),
            billingApi: billingApi(config.billingApiUrl),
            portClientApi: PortClientApi.create(),
            portOwnerApi: PortOwnerApi.create(),
            filesUpload: filesUpload(config.fsApiUrl),
          },
        },
        serializableCheck: false,
      }),
  });

type IStore = ReturnType<typeof getStore>;
export type RootState = ReturnType<IStore['getState']>;
export type AppDispatch = IStore['dispatch'];
