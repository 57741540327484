import { memo, useRef } from 'react';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import { useStyle } from 'src/utils/theme/useStyle';
import { modalListRules } from './ModalList.style';
import { IconList } from 'src/components/UI/Icon/Icon';
import { useTheme } from 'src/utils/theme/useTheme';
import { Option } from '../Option/Option';
import { ListStoreFocusValue } from '../../ListStoreFocus';
import { useAppSelector } from 'src/hooks/redux';
import { getWizardState } from 'src/redux/slices/wizardSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
// import Text from "src/components/UI/Text/Text";
// import { Info } from "src/components/UI/Info/info";

interface IProps {
  active: boolean;
  title?: string;
  options: { value: ListStoreFocusValue; text: string; icon: IconList }[];
  onChange: (value: string) => void;
  selectedValue: ListStoreFocusValue;
  onClose: () => void;
}

// stateless - отдельная обёртка компонента для работы со state, служит чтобы вынести стейт на самый верхний уровень

const ModalList = function ModalList(props: IProps) {
  const { active, selectedValue, options, onChange, onClose } = props;
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const { theme } = useTheme();
  const { css } = useStyle(modalListRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
  });

  const { saveNetworkStatus } = useAppSelector(getWizardState)

  const onChangeOption = e => {
    onChange(e)
    onClose()
  }

  return (
    <Modal
      active={active}
      name={ModalNames.List}
      onClose={onClose}
      propsStyles={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 12,
        backgroundColor: theme.background,
      }}
    >
      {/* <div ref={modalHeaderRef} className={css.header}>
        {title && <Text text={title} mod="title" textTransform={"uppercase"} />}
        <Info href="/" />
      </div> */}
      <div className={css.modalInner}>
        {options.map(option => (
          <Option
            disabled={saveNetworkStatus === NetworkStatus.Loading}
            key={option.value}
            value={option.value}
            text={option.text}
            icon={option.icon}
            selectedValue={selectedValue}
            type="radio"
            onChange={onChangeOption}
          />
        ))}
      </div>
    </Modal>
  );
};

export default memo(ModalList);
