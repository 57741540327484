import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IFooterRuleKeys {
  footer: {};
}

interface IFooterRuleProps {
  height: number;
  padding: string;
  endDocument: boolean;
  backgroundColor: string;
  staticFooter: boolean;
}

export type IFooterRules = IRuleFn<IFooterRuleKeys, IFooterRuleProps>;

const footerRuleFn: IFooterRules = props => {
  const { appMaxWidth, colorWhite } = props.theme;
  const { height, padding, endDocument, backgroundColor, staticFooter } = props;

  return {
    footer: {
      maxWidth: appMaxWidth,
      minHeight: height,
      width: '100%',
      position: staticFooter ? 'relative' : 'sticky',
      bottom: '0',
      zIndex: 10,
      alignItems: 'center',
      display: 'flex',
      columnGap: 12,
      padding: padding || '12px 16px 40px',
      backgroundColor: backgroundColor
        ? backgroundColor
        : endDocument
          ? colorWhite
          : 'rgba(255, 255, 255, .5)',
      backdropFilter: backgroundColor || endDocument ? 'none' : 'blur(20px)',
    },
  };
};

export const footerRules = createCachedStyle(footerRuleFn);
