import ReactDOM from 'react-dom/client';
import { RendererProvider, ThemeProvider } from 'react-fela';
import { Provider } from 'react-redux';
import App from './App';
import { renderer } from './styles/fela';

import { ThemeName } from './utils/theme/theme.interface';
import { getTheme } from './utils/theme/getTheme';
import { TelegramProvider } from './utils/telegramProvider';
import './index.css';
import { IConfig } from './utils/config/config.interface';
import { getStore } from './redux/store';
import StoreNotWorking from './components/StoreNotWorking/StoreNotWorking';
import { isConfigValid } from './utils/config/isConfigValid';
import { Theme } from './components/Theme/Theme';
import { setConfig, setTranslation } from './redux/slices/configSlice';
import { setIsAuthorized, setUserName } from './redux/slices/userSlice';
import { wizardGetState } from './redux/api/wizard/wizardGetState';
import { setAccentColor } from './redux/slices/wizardSlice';
import { ITranslation } from './utils/i18n/i18n.interface';

export function render(config: IConfig, translation: ITranslation, telegramLanguage) {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  if (!isConfigValid(config) || !translation) {
    root.render(
      <TelegramProvider>
        <ThemeProvider theme={getTheme(ThemeName.Light)}>
          <RendererProvider renderer={renderer}>
            <StoreNotWorking reasonText={'Invalid config'} />
          </RendererProvider>
        </ThemeProvider>
      </TelegramProvider>,
    );
  } else {
    const store = getStore(config);
    store.dispatch(setConfig(config));
    store.dispatch(setTranslation({ translation, language: telegramLanguage }));

    store
      .dispatch(wizardGetState())
      .unwrap()
      .then(res => {
        if (window?.Telegram?.WebApp?.initData) {
          store.dispatch(setIsAuthorized(true));
          store.dispatch(setUserName(window.Telegram.WebApp.initDataUnsafe.user.username));
        }

        if (!res.wizardData.accentColor) {
          store.dispatch(setAccentColor(config.accentColor));
        }

        root.render(
          <TelegramProvider>
            <Provider store={store}>
              <Theme>
                <RendererProvider renderer={renderer}>
                  <App />
                </RendererProvider>
              </Theme>
            </Provider>
          </TelegramProvider>,
        );
      })
      .catch(() => {
        root.render(
          <TelegramProvider>
            <ThemeProvider theme={getTheme(ThemeName.Light)}>
              <RendererProvider renderer={renderer}>
                <StoreNotWorking reasonText={'Error receiving wizard data'} />
              </RendererProvider>
            </ThemeProvider>
          </TelegramProvider>,
        );
      });
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
