import { ChangeEvent, FC, memo, useEffect, useMemo, useState} from 'react';
import { Layout } from 'src/components/Layout/Layout';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import { orderingAndDeliveryPageRules } from './OrderingAndDeliveryPage.style';
import useLoader from 'src/hooks/useLoader';
import { ListButton } from 'src/components/UI/ListButton/ListButton';
import { Textarea } from 'src/components/UI/Textarea/Textarea';
import { useModalState } from 'src/hooks/useModalState';
import { ModalUniversal } from 'src/components/ModalUniversal/ModalUniversal';
import { OptionCard } from 'src/components/OptionCard/OptionCard';
import { BASKET } from 'src/blocks/wizard/WizardHasBasket/WizardHasBasket';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { getWizardState, resetNetworkStatus, setMinimalPrice, setPurchaseConfirmationMessage } from 'src/redux/slices/wizardSlice';
import { DeliveryType } from '@teleport/schemas-protobuf';
import { wizardGetState } from 'src/redux/api/wizard/wizardGetState';
import { NetworkStatus } from 'src/utils/network/network.constant';
import Toggler from 'src/components/UI/Toggler/Toggler';
import { Input } from 'src/components/UI/Input/Input';
import { WizardTranslator } from 'src/redux/translators/wizardTranslator';
import { wizardSaveState } from 'src/redux/api/wizard/wizardSaveState';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { setDeliveryType } from 'src/redux/slices/configSlice';
import ModalError from 'src/components/ModalError/ModalError';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { ModalWarningAsyncChanges } from 'src/components/ModalWarningAsyncChanges/ModalWarningAsyncChanges';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import useCurrencySymbol from 'src/hooks/useCurrencySymbol';


interface IForm {
  hasBasket: BASKET,
  deliveryType: DeliveryType,
  purchaseConfirmationMessage: string,
  minimalPrice: number,
  minimalPriceEnabled: boolean
}


export const OrderingAndDeliveryPage: FC = memo(function OrderingAndDeliveryPage() {
  const { css } = useStyle(orderingAndDeliveryPageRules);
  const { hideLoader } = useLoader();
  const [renderModalUniversal, activeModalUniversal, openModalUniversal, closeModalUniversal] =
  useModalState();
  const [renderErrorModal, activeErrorModal, openErrorModal, closeErrorModal] = useModalState();
  const dispatch = useAppDispatch()
  const [modalTitle, setModalTitle] = useState('');
  const [modalType, setModalType] = useState('')
  const {
    wizard: wizardData,
    networkStatus,
    saveNetworkStatus,
  } = useAppSelector(getWizardState);
  const BackButton = useBackButton();
  const navigate = useNavigate();

  const [noShowModalWarningAsyncChanges] = useLocalStorage('noShowModalWarningAsyncChanges');
  const [renderModal, activeModal, openModal, closeModal] = useModalState();

  const [savedSuccessfully, setSavedSuccessfully] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    if (saveNetworkStatus === NetworkStatus.Done) {
      setSavedSuccessfully(true);
      setTimeout(() => {
        setSavedSuccessfully(false);
        if (!noShowModalWarningAsyncChanges) {
          openModal();
        }
      }, 1500);
      dispatch(resetNetworkStatus('saveNetworkStatus'));
      setTimeout(() => setSavedSuccessfully(false), 1500);
    }
  }, [saveNetworkStatus, noShowModalWarningAsyncChanges, dispatch, openModal]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(wizardGetState());
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, hideLoader, dispatch]);

  const onClickListButton = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLElement;
    const targetParentId = target.closest('.list-button')?.id;

    if (!target.id && !targetParentId) return;
    const targetId = target.id ? target.id : targetParentId;


    switch (targetId) {
      case 'deliveryType':
        setModalTitle(t('orderingAndDeliveryPage.deliveryType'))
        setModalType('deliveryType')
        openModalUniversal()
        return;
      case 'cart':
        setModalTitle(t('orderingAndDeliveryPage.cart'))
        setModalType('cartActivate')
        openModalUniversal()
        return;
      default:
        setModalTitle('default')
        return
    }
  }


  const onChangeMinimalPrice = event => {
    dispatch(setMinimalPrice(Number(event.target.value)))
  }

  const onChangeTextArea = event => {
    dispatch(setPurchaseConfirmationMessage(event.target.value))
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    control
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      hasBasket: wizardData.hasBasket,
      deliveryType: wizardData.deliveryType,
      purchaseConfirmationMessage: wizardData.purchaseConfirmationMessage,
      minimalPrice: wizardData.minimalPrice,
      minimalPriceEnabled: wizardData.minimalPriceEnabled
    },
  });

  const deliveryTypeValue =  useWatch({
    control,
    name: 'deliveryType',
  });
  const minimalPriceEnabledValue =  useWatch({
    control,
    name: 'minimalPriceEnabled',
  });
  const hasBasketValue =  useWatch({
    control,
    name: 'hasBasket',
  });

  const deliveryTypeTitle = useMemo(() => {
    switch (deliveryTypeValue) {
      case DeliveryType.SHOWCASE:
        return t('orderingAndDeliveryPage.showcase')
      case DeliveryType.REQUEST:
        return t('orderingAndDeliveryPage.request')
      case DeliveryType.DIGITAL:
        return t('orderingAndDeliveryPage.digital')
      case DeliveryType.PHYSICAL:
        return t('orderingAndDeliveryPage.physical')
      default:
        return t('orderingAndDeliveryPage.undefinedDeliveryType')
    }
  }, [deliveryTypeValue, t])

  const setMinimalAmount = () => {
    setValue('minimalPriceEnabled', !minimalPriceEnabledValue);
  }

  const onSubmit: SubmitHandler<IForm> = data => {
    const { hasBasket, deliveryType, purchaseConfirmationMessage, minimalPrice, minimalPriceEnabled } = data;
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest(
      // 'end',
      { ...wizardData, hasBasket, deliveryType, purchaseConfirmationMessage, minimalPrice, minimalPriceEnabled },
      true,
    );

    dispatch(wizardSaveState({ requestData: wizardStateRequest })).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(setDeliveryType(wizardData.deliveryType))
      }else {
        openErrorModal()
      }
    });
  };

  const changeHasBasket = event => {
    const value: BASKET = event.target.value;
    setValue('hasBasket', value);
    if (event.target.value === 'no_basket') {
      setValue('minimalPriceEnabled', false);
      setValue('minimalPrice', 0);
    }
    closeModalUniversal()
  };

  const onChangeMethod = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setValue('deliveryType', value);
    closeModalUniversal()
  };

  const disableShowCaseType = hasBasketValue === BASKET.HAS_BASKET
  const disabledBasket = deliveryTypeValue === DeliveryType.SHOWCASE;

  const submitForm = async () => {
    const result = await trigger();
    if (result) {
      handleSubmit(onSubmit)();
    }
  };

  const closeModalUniversalFuntion = () => {
    closeModalUniversal();
  }

  const currentCurrencySymbol = useCurrencySymbol(wizardData?.currency);

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('orderingAndDeliveryPage.checkoutAndDelivery')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
            textTransform="uppercase"
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            successfully={savedSuccessfully && networkStatus !== NetworkStatus.Loading}
            text={t('orderingAndDeliveryPage.save')}
            onClick={submitForm}
            propsStyles={{ width: '100%' }}
          />
        </Footer>
      }
    >
      <Container>
        <form >
          <ListButton
            id={'deliveryType'}
            padding="12px 0"
            titleFontSize={12}
            bg="transparent"
            title={t('orderingAndDeliveryPage.deliveryType')}
            placeholder={deliveryTypeTitle}
            onClick={onClickListButton}
          />
          <ListButton
            id={'cart'}
            padding="12px 0"
            titleFontSize={12}
            bg="transparent"
            title={t('orderingAndDeliveryPage.cart')}
            placeholder={hasBasketValue === BASKET.HAS_BASKET ? t('orderingAndDeliveryPage.yes') : t('orderingAndDeliveryPage.no')}
            onClick={onClickListButton}
          />
          {
            hasBasketValue === BASKET.HAS_BASKET && (
              <div className={css.wrapperJustify}>
                <Text text={t('orderingAndDeliveryPage.minimumOrderAmount')} mod="title" fontSize={12} />
                <Toggler
                  name={'disable-store'}
                  checked={minimalPriceEnabledValue}
                  value="show-in-catalog"
                  onChange={setMinimalAmount}
                />
              </div>
            )
          }

          {
            hasBasketValue === BASKET.HAS_BASKET && minimalPriceEnabledValue && (
              <Input
                name={'minimalPrice'}
                {...register('minimalPrice', {
                  validate: value =>
                    !minimalPriceEnabledValue || hasBasketValue !== BASKET.HAS_BASKET || value > 0 || t('orderingAndDeliveryPage.requiredField')
                })}
                onChange={onChangeMinimalPrice}
                controlled={false}
                type="number"
                placeholder={t('orderingAndDeliveryPage.enterMinAmount')}
                maxLength={70}
                symbol={currentCurrencySymbol}
                errorMessage={errors?.minimalPrice?.message}
              />
            )
          }
          <div className={css.textAreaWrp}>
            <Textarea
              name={'purchaseConfirmationMessage'}
              {...register('purchaseConfirmationMessage')}
              controlled={false}
              label={t('orderingAndDeliveryPage.messageTextToTheClient')}
              placeholder={t('orderingAndDeliveryPage.willBeSentToTheClientTelegramChatAfterPlacingAnOrder')}
              onChange={onChangeTextArea}
            />
          </div>

        </form>
      </Container>
      {renderModalUniversal && (
        <ModalUniversal
          title={modalTitle}
          active={activeModalUniversal}
          onClose={closeModalUniversalFuntion}
        >
          <>
            <div className={css.grid}>
              {
                modalType === 'deliveryType' && (
                  <>
                    <OptionCard
                      name="order-method"
                      title={t('orderingAndDeliveryPage.showcase')}
                      text={t('orderingAndDeliveryPage.ifYouWantToDisplayProductsAndRedirectCustomerToYourWebsiteToMakeAPurchase')}
                      type="radio"
                      value={`${DeliveryType.SHOWCASE}`}
                      disabled={disableShowCaseType}
                      disabledMessage={disableShowCaseType ? t('orderingAndDeliveryPage.theShowcaseIsNotCompatibleWithTheCart') : ''}
                      checked={disableShowCaseType? false: deliveryTypeValue === DeliveryType.SHOWCASE}
                      onChange={onChangeMethod}
                      bg={'#F0F0F0'}
                    />
                    <OptionCard
                      name="order-method"
                      title={t('orderingAndDeliveryPage.request')}
                      text={t('orderingAndDeliveryPage.ifYouWantToGetCustomerContactsAndCommunicateFurtherYourself')}
                      type="radio"
                      value={`${DeliveryType.REQUEST}`}
                      checked={deliveryTypeValue === DeliveryType.REQUEST}
                      onChange={onChangeMethod}
                      bg={'#F0F0F0'}
                    />
                    <OptionCard
                      name="order-method"
                      title={t('orderingAndDeliveryPage.digital')}
                      text={t('orderingAndDeliveryPage.ifYouSellCodesCouponsOrOtherDigitalProduct')}
                      type="radio"
                      value={'test'}
                      bg={'#F0F0F0'}
                      soon={true}
                    />
                    <OptionCard
                      name="order-method"
                      title={t('orderingAndDeliveryPage.physical')}
                      text={t('orderingAndDeliveryPage.ifYouDeliverByCourierOrThroughPickUpPoints')}
                      type="radio"
                      value={'test'}
                      bg={'#F0F0F0'}
                      soon={true}
                    />
                  </>
                )
              }

              {
                modalType === 'cartActivate' && (
                  <>
                    <OptionCard
                      name="hasBasket"
                      title={t('orderingAndDeliveryPage.yes')}
                      text={t('orderingAndDeliveryPage.theUserWillBeAblToOrderMultipleItemsAndProceedToCheckoutfromTheCart')}
                      type="radio"
                      value={BASKET.HAS_BASKET}
                      disabled={disabledBasket}
                      disabledMessage={disabledBasket ? t('orderingAndDeliveryPage.cartIsNotCompatibleWithShowcase') : ''}
                      checked={disabledBasket ? false : hasBasketValue === BASKET.HAS_BASKET}
                      onChange={changeHasBasket}
                      bg={'#F0F0F0'}
                    />
                    <OptionCard
                      name="hasBasket"
                      title={t('orderingAndDeliveryPage.no')}
                      text={t('orderingAndDeliveryPage.theUserWillBeAbleToOrderOnlyOneItemAndProceedToCheckoutFromTheProductPage')}
                      type="radio"
                      value={BASKET.NO_BASKET}
                      checked={disabledBasket ? true : hasBasketValue === BASKET.NO_BASKET}
                      onChange={changeHasBasket}
                      bg={'#F0F0F0'}
                    />
                  </>
                )
              }

            </div>
          </>
        </ModalUniversal>
      )}
      {renderModal && <ModalWarningAsyncChanges onClose={closeModal} active={activeModal} />}
      {renderErrorModal && (
        <ModalError
          onClose={closeErrorModal}
          title={'Ошибка'}
          active={activeErrorModal}
        />
      )}
    </Layout>
  );
});
