import { memo, useCallback, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Modal, { ModalNames } from '../Modal/Modal';
import { modalPromocodeRules } from './modalPromocode.style';
import { Button } from '../UI/Button/Button';
import Text from '../UI/Text/Text';
import { useTheme } from 'src/utils/theme/useTheme';
import { useScrollModal } from 'src/hooks/useScrollModal';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { WizardTranslator } from 'src/redux/translators/wizardTranslator';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { getWizardState } from 'src/redux/slices/wizardSlice';
import { wizardSaveState } from 'src/redux/api/wizard/wizardSaveState';

interface IProps {
  active: boolean;
  onClose: () => void;
  applyPromocode: (promocode: string) => void;
  value?: string;
}

const ModalPromocode = function ModalPromocode(props: IProps) {
  const dispatch = useAppDispatch();
  const { wizard } = useAppSelector(getWizardState);
  const { active, value = '', applyPromocode, onClose } = props;
  const [promocode, setPromocode] = useState(value);
  const [correctCode, setCorrectCode] = useState(true);
  const { css } = useStyle(modalPromocodeRules, { correctCode });
  const { theme } = useTheme();
  const { t } = useTranslation();

  const modalInnerRef = useRef<HTMLDivElement>(null);

  useScrollModal(modalInnerRef);

  const onChangePromocode = event => {
    setCorrectCode(true);
    setPromocode(event.target.value);
  };

  const clickKeyboard = useCallback(event => {
    const input = event.currentTarget;
    if (event.key === 'Enter') input.blur();
  }, []);

  const inputRef = useCallback(
    (node: HTMLInputElement) => {
      if (node !== null) {
        node.addEventListener('keypress', clickKeyboard);
      }
    },
    [clickKeyboard],
  );

  const checkPromocode = () => {
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest(
      { ...wizard, promocode: promocode.toUpperCase() },
      false,
    );
    dispatch(wizardSaveState({ requestData: wizardStateRequest }))
      .unwrap()
      .then(() => {
        setCorrectCode(true);
        applyPromocode(promocode);
        onClose();
      })
      .catch(err => {
        console.error(err);
        setCorrectCode(false);
      });
  };

  const clearPromocode = () => {
    setPromocode('');
    setCorrectCode(true);
  };

  return (
    <Modal
      active={active}
      name={ModalNames.PromoCode}
      propsStyles={{ paddingBottom: 30 }}
      onClose={onClose}
    >
      <div ref={modalInnerRef}>
        <div className={css.content}>
          <input
            ref={inputRef}
            placeholder={t('wizardModalPromo.placeholder')}
            name="promocode"
            type="text"
            className={css.inputPromocode}
            onChange={onChangePromocode}
            value={promocode}
          />
          {!correctCode && (
            <Text
              text={t('wizardModalPromo.codeExpired')}
              mod="text"
              fontSize={12}
              lineHeight={16}
              extend={css.incorrect}
            />
          )}
        </div>
        <footer className={css.footer}>
          <Button
            text={t('wizardModalPromo.apply')}
            propsStyles={{
              width: '100%',
            }}
            disabled={!correctCode || !Boolean(promocode)}
            onClick={checkPromocode}
          />
          {value && (
            <Button
              icon="cross"
              propsStyles={{
                background: theme.colorGrey,
                padding: '0',
                width: 62,
                height: 62,
              }}
              onClick={clearPromocode}
              extend={css.btnClear}
            />
          )}
        </footer>
      </div>
    </Modal>
  );
};

export default memo(ModalPromocode);
