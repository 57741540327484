import { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ProductImagesLoaderRules } from './ProductImagesLoader.style';
import Text from 'src/components/UI/Text/Text';
import {
  DndContext,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { SortableImage } from './components/SortableImage/SortableImage';
import { Icon } from 'src/components/UI/Icon/Icon';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';

export interface IFileEntity {
  id: string;
  file?: File;
  src?: string;
}

interface IProps {
  name: string;
  message?: string;
  onChangeFile?: (files: File[]) => void;
  onChangeUrlsOrder?: (imageUrls: string[]) => void;
  deleteImageUrls?: (url: string) => void;
  initialImages?: string[];
  uploadedFilesUrls?: string[];
  extend?: string;
}

// todo move to helper, fix types
async function cropAndResizeImage(file, size = 500) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => {
      const img: any = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const sideLength = Math.min(img.width, img.height);
        const startX = (img.width - sideLength) / 2;
        const startY = (img.height - sideLength) / 2;

        canvas.width = size;
        canvas.height = size;

        ctx.drawImage(img, startX, startY, sideLength, sideLength, 0, 0, size, size);

        canvas.toBlob(blob => {
          const croppedFile = new File([blob], file.name, { type: file.type });
          resolve(croppedFile);
        }, file.type);
      };
      img.onerror = reject;
      img.src = event.target.result;
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export const ProductImagesLoader: FC<IProps> = memo(function ProductImagesLoader(props) {
  const { name, message, extend, onChangeFile, deleteImageUrls, onChangeUrlsOrder, initialImages, uploadedFilesUrls } =
    props;
  const { css } = useStyle(ProductImagesLoaderRules);
  const sensors = useSensors(useSensor(TouchSensor), useSensor(PointerSensor));

  const { t } = useTranslation();

  const [files, setFiles] = useState<IFileEntity[]>(() => {
    if (initialImages) {
      return initialImages.map((image, index) => ({
        id: image,
        src: image,
        orderNumber: index + 1,
      }));
    }

    return [];
  });

  useEffect(() => {
    if (onChangeFile) {
      onChangeFile(files.filter(file => !Boolean(file.src)).map(({ file }) => file));
    }
    if (onChangeUrlsOrder) {
      onChangeUrlsOrder(
        files.map(file => {
          if (file.file) {
            return '';
          } else {
            return file.src;
          }
        }),
      );
    }
  }, [files, onChangeFile, onChangeUrlsOrder]);

  useEffect(() => {
    if (uploadedFilesUrls?.length > 0) {
      const newFiles = uploadedFilesUrls.map((url, index) => ({
        id: url,
        src: url,
        orderNumber: index + 1,
      }));
  
      setFiles(prev => {
        const existingIds = new Set(prev.map(file => file.id));
        const filteredNewFiles = newFiles.filter(file => !existingIds.has(file.id));
        return [...prev.filter(file => file.src?.length > 0), ...filteredNewFiles];
      });
    }
  }, [uploadedFilesUrls]);

  async function onChange(event: ChangeEvent<HTMLInputElement>) {
    const inputFiles = Array.from(event.target.files);
    for (let i = 0; i < inputFiles.length; i++) {
      const croppedFile: any = await cropAndResizeImage(inputFiles[i]);

      setFiles(files => [
        ...files,
        {
          id: croppedFile.lastModified.toString(),
          file: croppedFile,
          orderNumber: files.length + 1,
        },
      ]);
    }
  }

  let ts: number | undefined;
  const onTouchStart = (e: TouchEvent) => {
    ts = e.touches[0].clientY;
  };
  const onTouchMove = (e: TouchEvent) => {
    const te = e.changedTouches[0].clientY;
    if (ts < te) {
      e.preventDefault();
    }
  };

  function handleDragStart() {
    const deleteButtons = document.querySelectorAll<HTMLButtonElement>('.sortableDeleteButton');
    deleteButtons.forEach(button => {
      button.style.display = 'none';
    });
    document.documentElement.addEventListener('touchstart', onTouchStart, { passive: false });
    document.documentElement.addEventListener('touchmove', onTouchMove, { passive: false });
  }

  function handleDragEnd(event) {
    const deleteButtons = document.querySelectorAll<HTMLButtonElement>('.sortableDeleteButton');
    deleteButtons.forEach(button => {
      button.style.display = '';
    });
    document.documentElement.removeEventListener('touchstart', onTouchStart);
    document.documentElement.removeEventListener('touchmove', onTouchMove);
    const { active, over } = event;

    if (active.id !== over.id) {
      setFiles(files => {
        const dragEl = files.find(el => el.id === active.id);
        const dropEl = files.find(el => el.id === over.id);

        const oldIndex = files.indexOf(dragEl);
        const newIndex = files.indexOf(dropEl);

        const newArr = arrayMove(files, oldIndex, newIndex);
        return newArr;
      });
    }
  }

  function deleteFile(fileId: string) {
    deleteImageUrls && deleteImageUrls(fileId);
    // При удалении файла, также чистить массив с imageUrls
    setFiles(files.filter(file => file.id !== fileId));
  }

  return (
    <div className={css.loaderWrapper}>
      <div className={css.imagesWrapper}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={files} strategy={horizontalListSortingStrategy}>
            {files.length > 0 && files.map(file => {
              const src = file.src ?? URL.createObjectURL(file.file);
              const id = file.id;
              return <SortableImage key={id} src={src} id={id} deleteImageFn={deleteFile} />;
            })}
          </SortableContext>
        </DndContext>
        <label htmlFor={name}  className={css.loader}>
          <Icon name="plus" />
        </label>
      </div>

      <div className={[css.labelWrp, extend].join(' ')}>
        <input
          type="file"
          name={name}
          id={name}
          accept=".png, .jpg, .jpeg, .webp"
          onChange={onChange}
          multiple={true}
        />

        <Text
          text={t('productImagesLoader.imageUpload')}
          mod="text"
          extend={css.labelText}
          fontSize={12}
          lineHeight={12}
          fontWeight={700}
        />
        {message && (
          <Text text={message} mod="text" fontSize={12} lineHeight={14} extend={css.message} />
        )}
      </div>
    </div>
  );
});
