import { borderRadius, padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IProductsCardKeys {
  card: {};
  slider: {};
  slide: {};
  image: {};
  info: {};
  title: {};
  noCategoryText: {};
  categories: {};
  separate: {};
  statistics: {};
  innerInfo: {};
  leftInfo: {};
  rightInfo: {};
  bold: {};
  slideBtn: {};
  hiddenBtn: {};
  fallbackSvg: {};
}

interface IProductsCardProps {
  isHidden: boolean;
  isActive: boolean;
  isThird: boolean;
}

export type IProductsCard = IRuleFn<IProductsCardKeys, IProductsCardProps>;

const ProductsCardRuleFn: IProductsCard = props => {
  const { colorWhite, colorGrey, colorRed, colorDarkGrey, transitionDuration, mainColor, background } =
    props.theme;
  const { isHidden, isActive, isThird } = props;

  let borderColor = colorWhite;

  if (isThird) {
    borderColor = colorDarkGrey;
  }
  if (isActive) {
    borderColor = mainColor;
  }

  return {
    bold: {
      fontWeight: 600,
    },
    card: {
      maxHeight: 90,
      opacity: isHidden ? 0.5 : 1,
      touchAction: 'manipulation',
      userSelect: 'none',
      WebkitUserSelect: 'none',
      ...padding(10, 12),
      display: 'flex',
      columnGap: 8,
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      backgroundColor: colorWhite,
      borderRadius: 12,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor,
      transition: transitionDuration,
    },
    slider: {
      width: '100%',
      overflow: 'hidden',
      ...borderRadius(0, 8, 8, 0),
    },
    slide: {
      width: 'calc(100% - 16px)',
    },
    image: {
      position: 'relative',
      flexShrink: 0,
      width: 66,
      height: 66,
      background: background,
      borderRadius: 8,
      '& img': {
        width: '100%',
        height: '100%',
        borderRadius: 8,
        objectFit: 'cover',
        objectPosition: 'center',
      },
    },
    info: {
      width: '76%',
      flexShrink: 2,
      flexGrow: 1,
    },
    title: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      marginBottom: '4px',
      letterSpacing: '-0.14px',
    },
    noCategoryText: {
      color: colorRed,
    },
    categories: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 500,
      marginBottom: '2px',
    },
    separate: {
      marginLeft: '5px',
      marginRight: '5px',
    },

    innerInfo: {
      minHeight: '46px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    leftInfo: {
      maxWidth: '75%',
    },
    rightInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'end',
    },
    statistics: {
      color: colorDarkGrey,
      fontSize: '10px',
      lineHeight: '14px',
      fontWeight: 500,
      letterSpacing: '-0.4px',
    },
    slideBtn: {
      width: 44,
    },
    hiddenBtn: {
      width: 44,
      height: 90,
      borderRadius: 12,
      border: 'none',
      outline: 'none',
      backgroundColor: colorGrey,

      ':active': {
        opacity: 0.8,
      },
    },
    fallbackSvg: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
    },
  };
};

export const ProductsCardRules = createCachedStyle(ProductsCardRuleFn);
