import { FC, memo, useEffect, useState } from 'react';
import { Input } from '../UI/Input/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '../UI/Button/Button';
import { useTheme } from 'src/utils/theme/useTheme';
import { useStyle } from 'src/utils/theme/useStyle';
import { ClientSupportFormRules } from './ClientSupportForm.style';
import { WizardTranslator } from 'src/redux/translators/wizardTranslator';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { getWizardState, resetNetworkStatus, setSupportLink } from 'src/redux/slices/wizardSlice';
import { wizardSaveState } from 'src/redux/api/wizard/wizardSaveState';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { userState } from 'src/redux/slices/userSlice';
import { useFirstRender } from 'src/hooks/useFirstRender';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

interface IProps {
  sendRequest: boolean;
  closeModal?: () => void;
}

interface IFormClientSupport {
  supportLink: string;
}

export const ClientSupportForm: FC<IProps> = memo(function ClientSupportForm(props) {
  const { sendRequest, closeModal } = props;

  const { theme } = useTheme();

  const { userName } = useAppSelector(userState);

  const { css } = useStyle(ClientSupportFormRules);

  const dispatch = useAppDispatch();

  const { saveNetworkStatus, wizard } = useAppSelector(getWizardState);

  const { t } = useTranslation();

  const [savedSuccessfully, setSavedSuccessfully] = useState(false);

  const isFirstRender = useFirstRender();

  useEffect(() => {
    if (saveNetworkStatus === NetworkStatus.Done && !isFirstRender) {
      setSavedSuccessfully(true);
      setTimeout(() => {
        setSavedSuccessfully(false);
        dispatch(resetNetworkStatus('saveNetworkStatus'));
        closeModal && closeModal();
      }, 1500);
    }
  }, [saveNetworkStatus, dispatch, isFirstRender, closeModal]);

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      supportLink: wizard?.supportLink || `@${userName}`,
    },
  });

  const onSubmit: SubmitHandler<IFormClientSupport> = data => {
    const { supportLink } = data;
    dispatch(setSupportLink(supportLink));
    if (sendRequest) {
      const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest(
        { ...wizard, supportLink },
        true,
      );
      dispatch(wizardSaveState({ requestData: wizardStateRequest }));
    } else {
      closeModal && closeModal();
    }
  };

  const checkMustSymbol = (value: string) => {
    const regex = /^@/;
    if (regex.test(value)) {
      return true;
    } else {
      return t('wizardStepEnd.clientForm.valueMustBeginWithAt');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={css.wrapper}>
        <Input
          controlled={false}
          {...register('supportLink', {
            required: t('wizardStepEnd.clientForm.requiredField'),
            minLength: {
              value: 5,
              message: t('wizardStepEnd.clientForm.mustBeFiveSymbolsOrMore'),
            },
            validate: {
              checkSymbol: checkMustSymbol,
            },
          })}
          type="text"
          label={t('wizardStepEnd.clientForm.enterTelegramUserOrBotName')}
          placeholder={t('wizardStepEnd.clientForm.enterBlockHeader')}
          propsStyles={{ backgroundColor: theme.background }}
          maxLength={200}
          errorMessage={errors.supportLink?.message}
          telegramMask={true}
        />
      </div>

      <div>
        <Button
          disabled={saveNetworkStatus === NetworkStatus.Loading}
          successfully={savedSuccessfully}
          text={t('wizardStepEnd.clientForm.save')}
          propsStyles={{ width: '100%' }}
          type="submit"
        />
      </div>
    </form>
  );
});
