import { FC, memo, useEffect, useRef, useState } from 'react';
import { useStyle } from '../../../utils/theme/useStyle';
import { WizardStartRules } from './WizardStart.style';
import { Icon } from 'src/components/UI/Icon/Icon';
import { useTheme } from 'src/utils/theme/useTheme';
import Text from 'src/components/UI/Text/Text';
import { Button } from 'src/components/UI/Button/Button';
import Container from 'src/components/Container/Container';
import { useModalState } from 'src/hooks/useModalState';
import ModalPromocode from 'src/components/ModalPromocode/ModalPromocode';
import { freeTrialPeriod } from 'src/utils/constants';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';
import { useAppSelector } from '../../../hooks/redux';
import { togglesState } from '../../../redux/slices/configSlice';

interface IProps {
  promocode: string;
  changePromocode: (code: string) => void;
}

export const WizardStart: FC<IProps> = memo(function WizardStart(props) {
  const { promocode, changePromocode } = props;
  const { subscriptionEnabled } = useAppSelector(togglesState);
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  // useEffect(() => {
  //   localStorage.setItem("completeWizard", "false");
  // }, []);
  const [footerHeight, setFooterHeight] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    const appFooterHeight = document.querySelector<HTMLElement>('.footer').offsetHeight;
    setFooterHeight(appFooterHeight);
  }, []);

  const { css } = useStyle(WizardStartRules, {
    appFooterHeight: Number(footerHeight),
  });
  const { theme } = useTheme();
  const { colorGrey, colorBlack, mainColor } = theme;
  const btnRemoveCodeRef = useRef(null);

  const applyPromoCode = (nextPromoCode: string) => {
    changePromocode(nextPromoCode);
  };

  const removePromoCode = () => {
    changePromocode('');
  };

  const openPromoCodeModal = event => {
    const btnRemoveCode = btnRemoveCodeRef.current;
    if (btnRemoveCode.contains(event.target)) {
      return;
    }
    openModal();
  };

  return (
    <Container>
      <div className={css.inner}>
        <div className={css.top}>
          <div className={css.iconWrapper}>
            <Icon name="bookmark" properties={{ fill: mainColor, className: css.bookmark }}/>
            <Icon name="check" properties={{ fill: mainColor, className: css.check }}/>
          </div>
          <Text
            text={t('wizardStart.hello')}
            mod="title"
            fontSize={24}
            lineHeight={24}
            extend={css.title}
          />
          <Text
            text={t('wizardStart.setup')}
            mod="text"
            lineHeight={22}
            fontWeight={500}
          />
        </div>
        {subscriptionEnabled && (
          <>
            <div className={css.bottom}>
              {promocode ? (
                <div className={css.promocodeBanner} onClick={openPromoCodeModal}>
                  <Text
                    text={t('wizardStart.30DaysSubscription')}
                    mod="text"
                    fontSize={20}
                    lineHeight={22}
                    fontWeight={700}
                    extend={css.promocodeValue}
                  />
                  <div>
                    <Text
                      text={t('wizardStart.promoCode')}
                      mod="text"
                      fontSize={12}
                      fontWeight={500}
                      lineHeight={16}
                      extend={css.promocodeTitle}
                    />
                    <div className={css.promocode}>
                      <Text
                        text={promocode.toLowerCase()}
                        mod="text"
                        fontWeight={700}
                        fontSize={14}
                        lineHeight={18}
                        letterSpacing={-0.14}
                      />
                      <button
                        onClick={removePromoCode}
                        className={css.btnRemoveCode}
                        ref={btnRemoveCodeRef}
                      >
                        <Icon name="cross"/>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <Button
                  text={t('wizardStart.enterPromoCode')}
                  propsStyles={{
                    background: colorGrey,
                    width: '100%',
                    color: colorBlack,
                  }}
                  hasGradient={false}
                  onClick={openModal}
                />
              )}

              <div className={css.free}>
                <Text
                  text={t('wizardStart.freeTrial', freeTrialPeriod)}
                  mod="title"
                  fontWeight={700}
                  lineHeight={22}
                  extend={css.freeTitle}
                />
                <Text
                  text={t('wizardStart.forNewUsers')}
                  mod="text"
                  fontSize={12}
                  fontWeight={500}
                  lineHeight={16}
                  extend={css.freeSubtitle}
                />
              </div>
            </div>
            {renderModal && (
              <ModalPromocode
                active={activeModal}
                onClose={closeModal}
                applyPromocode={applyPromoCode}
                value={promocode}
              />
            )}
          </>
        )}
      </div>
    </Container>
  );
});
