import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from '../../utils/network/network.constant';
import {
  SelectionBlock,
  SliderBlock,
  StoriesBlock,
  TextBlock,
} from '@teleport/schemas-protobuf';
import { UiBlocksTranslator } from '../translators/uiBlocksTranslator';
import { createAppAsyncThunk } from '../../hooks/redux';

export interface IUiBlockWidthId extends IUiBlock {
  id: number;
}

export interface IUiBlock {
  blockData:
    | {
        value: TextBlock;
        case: 'textBlock';
      }
    | {
        value: StoriesBlock;
        case: 'storiesBlock';
      }
    | {
        value: SliderBlock;
        case: 'sliderBlock';
      }
    | {
        value: SelectionBlock;
        case: 'selectionBlock';
      }
    | { case: undefined; value?: undefined };
}

interface IUiBlocksSlice {
  uiBlocks: IUiBlockWidthId[];
  networkStatus: NetworkStatus;
}

const initialState: IUiBlocksSlice = {
  uiBlocks: [],
  networkStatus: NetworkStatus.None,
};

export const reorderUiBlocksList = createAppAsyncThunk(
  'uiBlocks/getRootUiBlocksList',
  async (data: { mainPageBlocks: IUiBlock[] }, thunkAPI) => {
    return await thunkAPI.extra.portOwnerApi().uIBlockReorder(data);
  },
);

export const fetchUiBlocks = createAppAsyncThunk('uiBlocks/fetchUiBlocks', async (_, thunkAPI) => {
  const result = await thunkAPI.extra.portClientApi().status({});

  return UiBlocksTranslator.fromRootUiBlocksListResponse(result);
});

export const uiBlocksSlice = createSlice({
  name: 'UiBlocks',
  initialState,
  reducers: {
    setUiBlocks: (state, { payload }: PayloadAction<IUiBlockWidthId[]>) => {
      state.uiBlocks = payload;
    },
    resetNetworkStatus: state => {
      state.networkStatus = NetworkStatus.None;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUiBlocks.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(fetchUiBlocks.fulfilled, (state, action) => {
      if(action.payload.length > 0) {
        state.uiBlocks = action.payload.map((el, i) => {
          return {
            ...el,
            id: i,
          };
        });
      } else {
        state.uiBlocks = [{
          id: 1,
          blockData: {
            value: new StoriesBlock({items: []}),
            case: 'storiesBlock'
          }
        }]

      }
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(fetchUiBlocks.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
  },
});

export const { setUiBlocks, resetNetworkStatus } = uiBlocksSlice.actions;
export const uiBlocksState = (state: RootState) => state[uiBlocksSlice.name];
