import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IMainPageHeaderKeys {
  shopLogo: {};
  shopLogoWrp: {};
  subscription: {};
  subscriptionTitle: {};
  wrapper: {};
  generalInfo: {};
  telegram: {};
  redColor: {};
}

export type IMainPageHeader = IRuleFn<IMainPageHeaderKeys>;

const MainPageHeaderRuleFn: IMainPageHeader = props => {
  const { colorDarkGrey, colorRed } = props.theme;
  const {} = props;

  return {
    shopLogo: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      objectFit: 'cover',
    },
    shopLogoWrp: {
      width: 38,
      height: 38,
    },
    subscription: {
      textAlign: 'right',
    },
    subscriptionTitle: {
      color: colorDarkGrey,
    },
    wrapper: {
      paddingTop: 10,
      paddingBottom: 12,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    generalInfo: {
      display: 'flex',
      columnGap: 10,
      alignItems: 'center',
    },
    telegram: {
      color: colorDarkGrey,
    },
    redColor: {
      color: colorRed
    }
  };
};

export const MainPageHeaderRules = createCachedStyle(MainPageHeaderRuleFn);
