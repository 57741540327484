import { FC, memo, useCallback, useMemo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ShopStateRules } from './ShopState.style';
import Text from 'src/components/UI/Text/Text';
import { Button } from 'src/components/UI/Button/Button';
import { useTheme } from 'src/utils/theme/useTheme';
import { useAppSelector } from 'src/hooks/redux';
import { configState } from 'src/redux/slices/configSlice';
import { useTelegram } from 'src/utils/telegramProvider';
import { ToastComponent } from 'src/components/ToastComponent/ToastComponent';
import useCustomToast from 'src/hooks/useCustomToast ';
import { useModalState } from 'src/hooks/useModalState';
import { ModalUniversal } from 'src/components/ModalUniversal/ModalUniversal';
import { MaintenanceEnabledForm } from 'src/components/MaintenanceEnabledForm/MaintenanceEnabledForm';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

export enum ShopDisableStateTxt {
  SubscriptionNotActive = 'Срок действия оплаченного тарифа истек',
  MaintenanceEnabled = 'MaintenanceEnabled',
  EmptyShop = 'Нет ни одного товара в продаже',
}

interface IShopStateProps {
  isMaintenanceEnabled: boolean;
  isShopHasNoProducts: boolean;
  shopDisabledText?: string;
}

export const ShopState: FC<IShopStateProps> = memo(function ShopState(props) {
  const { isMaintenanceEnabled, isShopHasNoProducts, shopDisabledText } = props;
  const { css } = useStyle(ShopStateRules, {
    subscriptionExpired: shopDisabledText === ShopDisableStateTxt.SubscriptionNotActive,
  });
  const { theme } = useTheme();
  const { config } = useAppSelector(configState);
  const Telegram = useTelegram();
  const { portBotUsername, portCodename } = config;
  const portUrl = `https://t.me/${portBotUsername}/port?startapp=${portCodename}`;
  const [toast, showToast] = useCustomToast();
  const { t } = useTranslation();

  const [renderModalUniversal, activeModalUniversal, openModalUniversal, closeModalUniversal] =
    useModalState();

  const onClickGoToStore = useCallback(() => {
    if (Telegram.webApp.initData) {
      Telegram.webApp.openTelegramLink(portUrl);
    } else {
      location.href = portUrl;
    }
  }, [Telegram.webApp, portUrl]);

  const shareStoreUrl = useCallback(() => {
    if (navigator.share) {
      navigator.share({ url: portUrl });
    } else {
      showToast(t('shopState.linkCopied'));
      navigator.clipboard.writeText(`${portUrl}`);
    }
  }, [portUrl, showToast, t]);

  const disableShopPlateClick = useCallback(() => {
    if (!isMaintenanceEnabled) return;
    openModalUniversal();
  }, [openModalUniversal, isMaintenanceEnabled]);

  const closeModalUniversalFuntion = () => {
    closeModalUniversal();
  };


  const content = useMemo(() => {
    if (isShopHasNoProducts) {
      return (
        <div className={css.bannerDisabled}>
          <Text
            mod="title"
            text={t('shopState.storeDisabled')}
            fontWeight={700}
            fontSize={16}
            lineHeight={18}
          />
          <Text
            mod="text"
            text={t('shopState.noProductsBeingSold')}
            fontWeight={500}
            fontSize={14}
            lineHeight={18}
          />
        </div>
      );
    }

    if (isMaintenanceEnabled) {
      return (
        <div className={css.bannerDisabled} onClick={disableShopPlateClick}>
          <Text
            mod="title"
            text={t('shopState.storeDisabled')}
            fontWeight={700}
            fontSize={16}
            lineHeight={18}
          />
          <Text
            mod="text"
            text={t('shopState.closedForMaintenance')}
            fontWeight={500}
            fontSize={14}
            lineHeight={18}
          />
        </div>
      );
    }

    return (
      <div className={css.buttons}>
        <Button
          text={t('shopState.goToTheStore')}
          propsStyles={{
            width: '100%',
            height: 62,
            background: theme.colorBlack,
          }}
          hasGradient={false}
          onClick={onClickGoToStore}
        />
        <ToastComponent positionX={'right'} positionY={'top'} text={toast}>
          <Button
            icon="share"
            extend={css.shareButton}
            propsStyles={{
              width: 62,
              height: 62,
              padding: '0px',
              background: theme.colorGrey,
            }}
            onClick={shareStoreUrl}
            hasGradient={false}
          />
        </ToastComponent>
      </div>
    );
  }, [isShopHasNoProducts, isMaintenanceEnabled, css, disableShopPlateClick, onClickGoToStore, shareStoreUrl, t, theme.colorBlack, theme.colorGrey, toast]);

  return (
    <div className={css.wrapper}>
      {content}
      {renderModalUniversal && (
        <ModalUniversal
          title={t('storeSetupPage.disablingTheStore')}
          active={activeModalUniversal}
          onClose={closeModalUniversalFuntion}
        >
          <MaintenanceEnabledForm onSubmitCallback={closeModalUniversal}/>
        </ModalUniversal>
      )}
    </div>
  );
});
