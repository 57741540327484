import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { MainPageHeaderRules } from './MainPageHeader.style';
import Text from 'src/components/UI/Text/Text';
import { useAppSelector } from 'src/hooks/redux';
import { configState, togglesState } from 'src/redux/slices/configSlice';
import { getWizardState } from 'src/redux/slices/wizardSlice';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { subscriptionState } from 'src/redux/slices/subscriptionSlice';
import { formatDate } from '../../utils/date/formatDate';

export const MainPageHeader: FC = memo(function MainPageHeader(props) {
  const {} = props;
  const { css } = useStyle(MainPageHeaderRules);
  const { config } = useAppSelector(configState);
  const { subscriptionEnabled } = useAppSelector(togglesState);
  const { wizard } = useAppSelector(getWizardState);
  const {finishedAt, subscriptionActive} = useAppSelector(subscriptionState)
  const { t } = useTranslation();

  return (
    <div className={css.wrapper}>
      <div className={css.generalInfo}>
        <div className={css.shopLogoWrp}>
          <img className={css.shopLogo} src={wizard.shopLogoUrl} alt="logo" />
        </div>
        <div>
          <Text
            mod="title"
            text={wizard.shopName}
            fontSize={14}
            fontWeight={600}
            lineHeight={16}
          />
          <Text
            mod="text"
            text={`@${config.portBotUsername}`}
            fontSize={12}
            fontWeight={500}
            lineHeight={16}
            extend={css.telegram}
          />
        </div>
      </div>
      {subscriptionEnabled && (
        <div className={css.subscription}>
          <Text
            mod="text"
            text={t('mainPageHeader.subscription')}
            fontSize={12}
            lineHeight={16}
            fontWeight={500}
            extend={css.subscriptionTitle}
          />
          <Text
            mod="text"
            text={subscriptionActive && finishedAt
              ? t('mainPageHeader.activeUntil', formatDate(finishedAt))
              : t('mainPageHeader.inactive')}
            fontSize={12}
            lineHeight={16}
            fontWeight={500}
            extend={!subscriptionActive? css.redColor : ''}
          />
        </div>
      )}
    </div>
  );
});
