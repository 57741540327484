import { FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import { Layout } from 'src/components/Layout/Layout';
import Container from 'src/components/Container/Container';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import { useModalState } from 'src/hooks/useModalState';
import { SettingSelectionPageRules } from './SettingSelectionsPage.style';
import useLoader from 'src/hooks/useLoader';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { useNavigate } from 'react-router-dom';
import useBackButton from 'src/hooks/useBackButton';
import { ModalSettingSelection } from 'src/blocks/settings-main/setting-selection/ModalSettingSelection/ModalSettingSelection';
import { SelectionCard } from 'src/blocks/settings-main/setting-selection/SelectionCard/SelectionCard';
import { selectionsState } from 'src/redux/slices/selectionsSlice';
import { getSelections } from 'src/redux/api/selections/getSelections';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { ISelectionWithProducts } from 'src/types/selection';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IProps {
  test?: string;
}

export const SettingSelectionPage: FC<IProps> = memo(function SettingSelectionPage(props) {
  const {} = props;
  const { css } = useStyle(SettingSelectionPageRules);
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const { networkStatus, selections } = useAppSelector(selectionsState);
  const { showLoader, hideLoader } = useLoader();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selection, setSelection] = useState<ISelectionWithProducts>(null);
  const [actionType, setActionType] = useState<'editing' | 'creating'>('creating');

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getSelections());
    }
    if (networkStatus === NetworkStatus.Loading) {
      showLoader();
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, dispatch, hideLoader, showLoader]);

  function openEditingModal(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const selectionId = event.currentTarget.id;
    const selection = selections.find(el => el.uuid === selectionId);
    if (!selectionId) return;
    setSelection(selection);
    setActionType('editing');
    openModal();
  }

  function openCreatingModal() {
    setSelection(null);
    setActionType('creating');
    openModal();
  }

  return (
    <Layout
      padding={'6px 0 0'}
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('settingSelectionPage.selections')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            text={t('settingSelectionPage.createSelection')}
            propsStyles={{ width: '100%' }}
            onClick={openCreatingModal}
          />
        </Footer>
      }
    >
      <Container extend={css.container}>
        {selections.length > 0 ? (
          <div className={css.textBlocksWrapper}>
            {selections.map(el => (
              <SelectionCard selectionData={el} key={el.uuid} openEditModal={openEditingModal} />
              //   <TextBlockCard cardData={el} key={el.id} onClick={openEditingModal} />
            ))}
          </div>
        ) : (
          <NoSettings
            title={t('settingSelectionPage.youHaventCreatedAnyCollectionsYet')}
            text={t('settingSelectionPage.createCollectionsFromYourProductsWeWillPlaceThemOnTheStoresHomepage')}
            icon="selections-large"
          />
        )}
      </Container>
      {renderModal && (
        <ModalSettingSelection
          active={activeModal}
          onClose={closeModal}
          selection={selection}
          actionType={actionType}
        />
      )}
    </Layout>
  );
});
