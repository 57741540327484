import { FC, memo, useCallback, useEffect, useState } from 'react';
import useLoader from 'src/hooks/useLoader';
import { Layout } from 'src/components/Layout/Layout';
import { Button } from 'src/components/UI/Button/Button';
import Container from 'src/components/Container/Container';
import { ImageLoader } from 'src/components/UI/ImageLoader/ImageLoader';
import { ListButton } from 'src/components/UI/ListButton/ListButton';
import { useStyle } from 'src/utils/theme/useStyle';
import { profilePageRules } from './ProfilePage.style';
import { Input } from 'src/components/UI/Input/Input';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { getWizardState, resetNetworkStatus } from 'src/redux/slices/wizardSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { wizardGetState } from 'src/redux/api/wizard/wizardGetState';
import { SubmitHandler, useForm } from 'react-hook-form';
import { wizardSaveState } from 'src/redux/api/wizard/wizardSaveState';
import { WizardTranslator } from 'src/redux/translators/wizardTranslator';
import useBackButton from 'src/hooks/useBackButton';
import { useModalState } from 'src/hooks/useModalState';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { ModalWarningAsyncChanges } from 'src/components/ModalWarningAsyncChanges/ModalWarningAsyncChanges';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { subscriptionState } from 'src/redux/slices/subscriptionSlice';
import { formatDate } from '../../utils/date/formatDate';
import { togglesState } from '../../redux/slices/configSlice';

interface IFormProfile {
  shopName: string;
  shopDescription: string;
}

export const ProfilePage: FC = memo(function ProfilePage() {
  const dispatch = useAppDispatch();
  const { networkStatus, saveNetworkStatus, wizard } = useAppSelector(getWizardState);
  const [logo, setLogo] = useState<{ shopLogo: null | File; shopLogoUrl: string }>({
    shopLogo: null,
    shopLogoUrl: wizard.shopLogoUrl,
  });
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);
  const navigate = useNavigate();
  const { hideLoader } = useLoader();
  const [footerHeight, setFooterHeight] = useState(0);
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [noShowModalWarningAsyncChanges] = useLocalStorage('noShowModalWarningAsyncChanges');
  const BackButton = useBackButton();
  const {finishedAt, subscriptionActive} = useAppSelector(subscriptionState)
  const {subscriptionEnabled} = useAppSelector(togglesState)

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const footerRef = useCallback(node => {
    if (node !== null) {
      setFooterHeight(node.clientHeight);
    }
  }, []);
  const { css } = useStyle(profilePageRules, { footerHeight });

  const { t } = useTranslation()

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(wizardGetState());
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, dispatch, hideLoader]);

  useEffect(() => {
    if (saveNetworkStatus === NetworkStatus.Done) {
      setSavedSuccessfully(true);
      setTimeout(() => {
        setSavedSuccessfully(false);
        if (!noShowModalWarningAsyncChanges) {
          openModal();
        }
      }, 1500);
      dispatch(resetNetworkStatus('saveNetworkStatus'));
    }
  }, [saveNetworkStatus, noShowModalWarningAsyncChanges, dispatch, openModal]);

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      shopName: wizard.shopName,
      shopDescription: wizard.shopDescription,
    },
  });

  const onSubmit: SubmitHandler<IFormProfile> = data => {
    const { shopDescription, shopName } = data;

    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest(
      // 'end',
      { ...wizard, shopName, shopDescription },
      true,
    );

    dispatch(wizardSaveState({ requestData: wizardStateRequest, shopLogoFile: logo.shopLogo }));
  };

  const changeShopLogo = (logo: File) => {
    const url = URL.createObjectURL(logo);
    setLogo({ shopLogo: logo, shopLogoUrl: url });
  };

  const goToTheSubscriptionPage = () => {
    return
    navigate('/subscription');
  };

  const formattedDate = formatDate(finishedAt);

  return (
    <Layout>
      <Container>
        <div className={css.container}>
          <div className={css.imgLoader}>
            <ImageLoader
              onChangeFile={changeShopLogo}
              imageUrl={logo.shopLogoUrl}
              name={'shop-avatar'}
              propsStyles={{ width: 80, height: 80, borderRadius: '50%' }}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={css.grid}>
              {subscriptionEnabled && (
                <ListButton
                  title={t('profilePage.subscription')}
                  placeholder={t('profilePage.untilDate', formattedDate)}
                  onClick={goToTheSubscriptionPage}
                  padding="16px"
                  redPlaceholder={!subscriptionActive}
                />
              )}

              <Input
                {...register('shopName', { required: t('profilePage.requiredField') })}
                type='text'
                placeholder={t('profilePage.storeName')}
                controlled={false}
                label={t('profilePage.storeName')}
                errorMessage={errors.shopName?.message}
                labelTextTransform='uppercase'
              />

              <Input
                type='text'
                {...register('shopDescription')}
                controlled={false}
                label={t('profilePage.storeDescription')}
                placeholder={t('profilePage.fillInTheStoreDescriptionWeDisplayItOnTelegram')}
                labelTextTransform='uppercase'
                maxLength={24}
                letterCounter={true}
              />
            </div>
            <div className={css.footer} ref={footerRef}>
              <Button
                successfully={savedSuccessfully}
                text={t('profilePage.save')}
                propsStyles={{ width: '100%' }}
                type="submit"
              />
            </div>
          </form>
        </div>
      </Container>
      {renderModal && <ModalWarningAsyncChanges onClose={closeModal} active={activeModal} />}
    </Layout>
  );
});
