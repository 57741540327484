import { ChangeEvent, FC, memo } from 'react';
import { Step } from 'src/components/Step/Step';
import { useStyle } from 'src/utils/theme/useStyle';
import { OptionCard } from 'src/components/OptionCard/OptionCard';
import { WizardDeliveryTypeRules } from './wizardOrderMethod.style';
import { DeliveryType } from '@teleport/schemas-protobuf';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';

interface IProps {
  setDeliveryTypeMethod: (method: DeliveryType) => void;
  deliveryType: DeliveryType;
}

export const WizardDeliveryType: FC<IProps> = memo(function WizardDeliveryType(props) {
  const { deliveryType, setDeliveryTypeMethod } = props;
  const { css } = useStyle(WizardDeliveryTypeRules);

  const { t } = useTranslation();

  const onChangeMethod = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setDeliveryTypeMethod(value);
  };

  const isSelected = value => {
    return deliveryType === value;
  };

  return (
    <Step
      stepNumber={4}
      title={t('wizardStepOrderMethod.chooseDeliveryAndOrderType')}
      subtitle={t('wizardStepOrderMethod.theWayYouDeliverAndTheWayCustomerOrders')}
    >
      <div className={css.wrapper}>
        <OptionCard
          name="order-method"
          title={t('wizardStepOrderMethod.showcase')}
          text={t(
            'wizardStepOrderMethod.ifYouWantToDisplayProductsAndRedirectCustomerToYourWebsiteToPurchase',
          )}
          type="radio"
          value={`${DeliveryType.SHOWCASE}`}
          checked={isSelected(DeliveryType.SHOWCASE)}
          onChange={onChangeMethod}
        />
        <OptionCard
          name="order-method"
          title={t('wizardStepOrderMethod.request')}
          value={`${DeliveryType.REQUEST}`}
          text={t(
            'wizardStepOrderMethod.ifYouWantToGetCustomerContactsAndCommunicateFurtherYourself',
          )}
          type="radio"
          checked={isSelected(DeliveryType.REQUEST)}
          onChange={onChangeMethod}
        />
        <OptionCard
          name="order-method"
          title={t('wizardStepOrderMethod.digital')}
          value={`${DeliveryType.DIGITAL}`}
          text={t('wizardStepOrderMethod.ifYouSellCodesCouponsOrOtherDigitalProducts')}
          type="radio"
          soon={true}
          checked={isSelected(DeliveryType.DIGITAL)}
          onChange={onChangeMethod}
        />
        <OptionCard
          name="order-method"
          title={t('wizardStepOrderMethod.physical')}
          value={`${DeliveryType.PHYSICAL}`}
          text={t('wizardStepOrderMethod.ifYouDeliverByCourierOfThroughPickUpPoints')}
          type="radio"
          soon={true}
          checked={isSelected(DeliveryType.PHYSICAL)}
          onChange={onChangeMethod}
        />
      </div>
    </Step>
  );
});
