import { FC, memo, useCallback } from 'react';
import { Step } from 'src/components/Step/Step';
import { Input } from 'src/components/UI/Input/Input';
import { useStyle } from 'src/utils/theme/useStyle';
import { ImageLoader } from 'src/components/UI/ImageLoader/ImageLoader';
import { WizardShopNameRules } from './WizardShopName.style';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';

interface IWizardShopName {
  logoUrl: string;
  shopName: string;
  shopDescription: string;
  setShopDescription: (value: string) => void;
  setShopName: (shopname: string) => void;
  setShopLogo: (shopLogo: File) => void;
}

export const WizardShopName: FC<IWizardShopName> = memo(function WizardShopName(props) {
  const { logoUrl, shopName, shopDescription, setShopName, setShopDescription, setShopLogo } = props;
  const { css } = useStyle(WizardShopNameRules);

  const {t} = useTranslation();

  const onChangeName = event => {
    setShopName(event.target.value);
  };

  const onChangeDescription = useCallback(event => {
    setShopDescription(event.target.value);
  }, [setShopDescription])

  const onChangeFile = (file: File) => {
    if (file) {
      setShopLogo(file);
    }
  };

  return (
    <Step
      stepNumber={1}
      title={t('wizardStepName.storeNameAndLogo')}
      subtitle={t('wizardStepName.willBeShownOnTelegram')}
      paddingBottom={0}
    >
      <ImageLoader
        onChangeFile={onChangeFile}
        imageUrl={logoUrl}
        name={'shop-avatar'}
        propsStyles={{ width: 100, height: 100, borderRadius: '50%' }}
        extend={css.imagePicker}
      />
      <div className={css.fieldWrapper}>
        <Input
          name="shop-name"
          type="text"
          controlled={true}
          value={shopName}
          label={t('wizardStepName.name')}
          placeholder={t('wizardStepName.enterStoreName')}
          labelTextTransform={'uppercase'}
          onChange={onChangeName}
        />
      </div>
      <div className={css.fieldWrapper}>
        <Input
          name="shop-description"
          type="text"
          controlled={true}
          value={shopDescription}
          label={t('wizardStepName.description')}
          placeholder={t('wizardStepName.forExampleAClothingStore')}
          labelTextTransform={'uppercase'}
          onChange={onChangeDescription}
          letterCounter={true}
          maxLength={24}
        />
      </div>
    </Step>
  );
});
